<div class="outer-container">
  <div class="header-container">
    <app-header-login></app-header-login>
  </div>

  <div class="forms-container mt-20">
    <mat-icon class="icon-72 lilac-icon mb-30">schedule</mat-icon>
    <h1 class="h1 w-328 text-center">Estamos validando los datos ingresados</h1>
    <div class="text-center mb-30 w-320">
      <span class="size-16"
        >Te avisaremos vía email cuando tu empresa se encuentre habilitada para
        operar.</span
      >
    </div>

    <div class="simple-container w-320">
      <button mat-fab extended class="button-blue w-full" (click)="onClick()">
        Agregar otro CUIT
      </button>
    </div>
  </div>

  <app-copyright></app-copyright>
</div>
