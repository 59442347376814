<div class="outer-container">
  <div class="header-container">
    <app-header-login></app-header-login>
  </div>

  <form
    [formGroup]="form"
    (ngSubmit)="validate2FACode()"
    class="forms-container mt-20">
    <h1 class="h1 w-300 text-center">
      Ingresá el código enviado a su
      {{ validationMethod === 'email' ? 'correo electrónico' : 'celular' }}
    </h1>
    <mat-icon class="icon-72 light-blue mt-10">mark_chat_read</mat-icon>
    <span class="text-center mb-30">Ingresá el código de 6 caracteres</span>
    <mat-form-field class="col-12 text-center big-input">
      <input
        matInput
        formControlName="code"
        maxlength="6"
        class="text-center big-input"
        type="tel"
        autocomplete="off" />
      <mat-error *ngIf="!form.valid">
        <span *ngIf="form.controls.code.hasError('required')">
          El código es requerido
        </span>
        <span *ngIf="form.controls.code.hasError('pattern')">
          El código debe ser de 6 dígitos
        </span>
      </mat-error>
    </mat-form-field>
    <p class="contador mat-body-2 opacity-50 mb-0 mt-20">
      <span *ngIf="showSendButtonSpinner">
        <mat-spinner diameter="25" color="blue" class="mx-auto"></mat-spinner>
      </span>
      <span *ngIf="!showSendButton && !showSendButtonSpinner">
        Reenviar código en {{ leftTime }} segundos...
      </span>
      <button
        *ngIf="showSendButton && !showSendButtonSpinner"
        mat-button
        class="button-transparent"
        type="button"
        (click)="send2FACode(validationMethod)">
        Reenviar código
      </button>
    </p>
    <div class="simple-container w-300 mt-30">
      <button
        mat-fab
        extended
        class="button-blue text-uppercase"
        type="submit"
        [disabled]="mostrarSpinner || !form.valid">
        <span *ngIf="!mostrarSpinner"> Verificar </span>
        <span *ngIf="mostrarSpinner">
          <mat-spinner diameter="25" color="blue" class="mx-auto"></mat-spinner>
        </span>
      </button>
    </div>
    <button
      mat-fab
      extended
      class="button-transparent"
      routerLink="/auth/externos">
      Seleccionar otro método de verificación
    </button>
  </form>

  <app-copyright></app-copyright>
</div>
