import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'transportes';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'whatsapp',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icon-whatsapp.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'union',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/union.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icon-google.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icon-fb.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'microsoft',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icon-ms.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'move_selection_left',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/move_selection_left.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'move_selection_up',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/move_selection_up.svg'
      )
    );
  }
}
