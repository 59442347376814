import { FormControl } from '@angular/forms';

export interface ConfirmationResponse {
  body: object;
  statusCode: number;
  statusText: string;
}

export interface AuthLoginResponseStoredUser {
  id_usuario: number;
  usuario: string;
  email: string;
  phone_number: string;
  phone_verified: boolean;
  nombre_apellido: string;
  role_id: number;
  last_login: string;
  status: number;
  role: Role;
  userCompanies: UserCompany[];
}
export interface Role {
  id: number;
  name: string;
  tag: string;
}
export interface Conditions {
  id_usuario?: string;
  subledger_empresa?: string;
  transportista_id?: string;
  is_unagro?: string;
  empresa_subledger?: string;
  is_marcas?: string;
}

export interface UserCompany {
  id: number;
  company: Company;
}

export interface Company {
  id: number;
  subledger: number;
  razon_social: string;
  cuit: string;
  companyBusinessTypes: CompanyBusinessType[];
}

export interface CompanyBusinessType {
  id_tipo_negocio: number;
  businessType: BusinessType;
}

export interface BusinessType {
  id: number;
  nombre: string;
  descripcion?: string | null;
}

export interface ResponseLoginValidatedLevel {
  type: string;
  contact: string;
}

export interface ResponseLoginValidated {
  levels: ResponseLoginValidatedLevel[];
  token: string;
}

export interface ResponseLoginNotValidated {
  email: string;
  phone: string;
}

declare enum Options {
  'email' = 'email',
  'phone' = 'phone',
}

export type TokenResponse = {
  token: string;
  refreshToken: string;
};

export enum ValidationMethod {
  NONE = 'none',
  EMAIL = 'email',
  SMS = 'sms',
  WHATSAPP = 'whatsApp',
  DUO = 'duo',
}

export enum Provider {
  GOOGLE = 'GOOGLE',
  META = 'META',
  MICROSOFT = 'MICROSOFT',
  ML = 'ML',
}

export type ValidateAccountResponse = {
  [method in Options]: string;
};

export type LevelResponse = {
  type: ValidationMethod | Provider;
  contact: string;
};

export type LevelsAvailable = {
  levels: LevelResponse[];
  token: string;
  expiration: number;
  session?: string;
};

export interface AuthLoginResponse<T> {
  statusCode: number;
  statusText: string;
  body: T;
}

export interface ResponseSendCode {
  isCorrect: boolean;
  result: string;
}

export interface Success2FACodeValidationResponse {
  user: AuthLoginResponseStoredUser;
  permissions: AuthLoginResponsePermissions;
  token: string;
  refreshToken: string;
  created?: boolean;
}

export interface CodeValidationForm {
  code: FormControl<string | null>;
}

export enum PermissionsTypes {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type AuthLoginResponsePermissions = string[];

export interface SideBar {
  title: string;
  module: string;
  links: SideLink[];
}

export interface SideLink {
  linkName: string;
  route: string;
  permissionName: string;
}

export type SideBarOptionsArr = SideBarOption[];

export interface SideBarOption {
  icon: string;
  optionTitle: string;
  routerLink: string;
  moreOptions?: boolean;
  permissionName: string;
  business?: string;
}

export interface UserCreate {
  firstName: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
}

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  phone: string;
  hashMethodAppId: number;
  deletedAt?: string;
  role: Role;
}
