<div class="outer-container">
  <div class="header-container">
    <app-header-login></app-header-login>
  </div>

  <div class="forms-container mt-20">
    <img class="mb-20" src="../../../../assets/brand/DUO.svg" alt="DUO" />
    <h1 class="h1">Verifique su identidad</h1>
    <div class="text-center mt-5 mb-15">
      Necesitamos que apruebes la notificación de DUO que enviamos a tu celular.
    </div>

    <mat-icon class="icon-120 light-blue mt-30 mb-30">mobile_friendly</mat-icon>

    <div class="simple-container">
      <button mat-fab extended class="button-transparent">
        Seleccionar otro método de verificación
      </button>
    </div>
  </div>

  <app-copyright></app-copyright>
</div>
