<div
  class="modal-dialog-wrap flex col gap-10"
  [ngStyle]="{ 'padding-bottom': isMobil ? '20px' : '' }">
  <button
    mat-icon-button
    (click)="this.dialogRef.close()"
    class="close-button align-self-end">
    <mat-icon>close</mat-icon>
  </button>

  <h1 class="text-center">¡Oops!</h1>
  <h1 class="text-center bold">Tu sesión ha expirado.</h1>
  <p class="mat-typography text-center">
    Hemos notado que tu sesión ha caducado. No te preocupes, para continuar, por
    favor, inicia sesión nuevamente
  </p>

  <button
    mat-button
    (click)="this.dialogRef.close()"
    class="button-blue mat-typography h-45 text-center bold">
    Volver a Iniciar Sesión
  </button>
</div>
