import {
  GoogleLoginProvider,
  MicrosoftLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/core/constants/notification.constants';
import { Success2FACodeValidationResponse } from '@app/core/interfaces/auth/auth.interface';
import { AuthService } from '@app/core/services/auth/auth.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-oauth-login',
  styleUrls: ['./oauth-login.component.scss'],
  templateUrl: './oauth-login.component.html',
})
export class OauthLoginComponent implements OnInit, OnDestroy {
  @Input() provider = '';
  subscription!: Subscription;
  constructor(
    private authSocialService: SocialAuthService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.subscription = this.authSocialService.authState.subscribe(user => {
      if (user) {
        this.authService.socialLogIn(user).subscribe({
          error: (err: HttpErrorResponse) => {
            this.notificationService.showNotification(
              err.error.message,
              CONSTANTS.CLOSE
            );
          },
          next: (response: Success2FACodeValidationResponse) => {
            if (response.created) {
              this.notificationService.showNotification(
                `Se a creado una cuenta con ${user.provider}, recomendamos activar el doble factor.`,
                CONSTANTS.CLOSE
              );
            }
            this.authService.saveAndRedirect(response, true);
          },
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public signInWithGoogle(): void {
    this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  public signInWithAzure(): void {
    this.authSocialService.signIn(MicrosoftLoginProvider.PROVIDER_ID);
  }
}
