/**
 * Esto matchea los roles del backend
 */
export const ROLES = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  ADMINISTRADOR_LOGISTICA: 'ADMINISTRADOR_LOGISTICA',
  ADMINISTRADOR_UNAGRO: 'ADMINISTRADOR_UNAGRO',
  USUARIO_CHOFER: 'CHOFER',
  USUARIO_LIQUIDADOR: 'LIQUIDADOR',
  USUARIO_PROGRAMADOR: 'PROGRAMADOR',
  USUARIO_SUPERVISOR: 'SUPERVISOR',
  USUARIO_TRANSPORTE: 'USUARIO_TRANSPORTE',
};

export type RoleType = keyof typeof ROLES;
