import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as LOCAL_STORAGE from '@app/core/constants/local-storage.constants';
import { AuthLoginResponseStoredUser } from '@app/core/interfaces/auth/auth.interface';
import { AuthService } from '@app/core/services/auth/auth.service';
import { LocalStorageService } from '@app/core/services/localStorage/local-storage.service';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
})
export class HeaderLoginComponent {
  public email: string;

  constructor(
    private authSocialService: SocialAuthService,
    public authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    const checkEmail = this.localStorageService.getItem(
      LOCAL_STORAGE.CONSTANTS.EMAIL
    ) as string;
    if (checkEmail) {
      this.email = checkEmail;
    } else {
      const user = this.localStorageService.getItem(
        LOCAL_STORAGE.CONSTANTS.USER
      ) as AuthLoginResponseStoredUser;
      this.email = user.email;
    }
  }

  logOut() {
    this.authService.logOut().subscribe({
      next: async () => {
        this.authService.deleteLoginData();
        const socialUser = this.localStorageService.getItem(
          LOCAL_STORAGE.CONSTANTS.SOCIAL_USER
        );
        if (socialUser) {
          await this.authSocialService.signOut();
          this.localStorageService.removeItem(
            LOCAL_STORAGE.CONSTANTS.SOCIAL_USER
          );
        }
        this.router.navigate(['/auth/login']);
      },
    });
  }
}
