<div class="outer-container">
  <div class="header-container">
    <app-header-login></app-header-login>
  </div>

  <div class="forms-container">
    <h1 class="h1 text-center">Asocie un CUIT a su cuenta</h1>
    <div class="text-center mt-5 mb-15 w-320">
      <p class="size-16">
        Antes de continuar, necesitamos asociar los datos de una empresa a su
        cuenta.
      </p>
    </div>

    <div class="flex row align-left mb-15 w-320 gap-10">
      <mat-chip-grid>
        @for (empresa of empresas; track empresa) {
          <mat-chip-row (removed)="remove(empresa.cuit_empresa)">
            {{ empresa.cuit_empresa }}
            <button
              matChipRemove
              [attr.aria-label]="'Eliminar solicitud ' + cuit">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
      </mat-chip-grid>
    </div>

    <form
      class="form-wrapper"
      [formGroup]="cuitForm"
      (ngSubmit)="enviarSolicitud()">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>CUIT</mat-label>
        <input
          matInput
          [disabled]="!!cuit?.hasError('tenCuits')"
          type="tel"
          formControlName="cuit"
          id="cuit" />
        <mat-error *ngIf="cuit?.hasError('required')">
          Ingrese un CUIT.
        </mat-error>
        <mat-error
          *ngIf="!cuit?.hasError('required') && cuit?.hasError('validaCuit')">
          Ingrese un CUIT válido.
        </mat-error>
        <mat-error *ngIf="cuit?.hasError('isRepeated')">
          Ya existe ese CUIT.
        </mat-error>
        <mat-hint
          ><span>Podés asociar hasta 10 CUIT por usuario.</span></mat-hint
        >
      </mat-form-field>

      <div class="simple-container justify-between gap-80">
        <button
          mat-fab
          extended
          class="button-transparent mb-30 w-full"
          [disabled]="showSpinner || cuit?.invalid"
          (click)="addCompany()">
          Agregar CUIT
        </button>
        <button
          mat-fab
          extended
          [disabled]="!empresas.length"
          class="button-blue text-uppercase"
          type="submit">
          Enviar
        </button>
      </div>
    </form>
  </div>

  <app-copyright></app-copyright>
</div>
