import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as LOCAL_STORAGE from '@app/core/constants/local-storage.constants';
import * as NOTIFICATIONS from '@app/core/constants/notification.constants';
import { ROLES } from '@app/core/constants/roles.constants';
import { PATHS } from '@app/core/interfaces/utils/router-path';
import { LocalStorageService } from '@app/core/services/localStorage/local-storage.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import {
  AuthLoginResponse,
  AuthLoginResponseStoredUser,
  LevelsAvailable,
  Success2FACodeValidationResponse,
  ValidateAccountResponse,
  ValidationMethod,
} from '@core/interfaces/auth/auth.interface';
import { AuthService } from '@core/services/auth/auth.service';
import { hasDot } from '@core/validators/custom.validators';
import { environment } from '@environment/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { take } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  loginForm: FormGroup = new FormGroup({});
  public mostrarSpinner: boolean;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private recaptchaService: ReCaptchaV3Service
  ) {
    this.mostrarSpinner = false;
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.email, hasDot()]),
      ],
      password: ['', Validators.required],
    });
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }
  get invalidPassword(): boolean | undefined {
    return (
      this.password?.invalid && (this.password?.touched || this.password?.dirty)
    );
  }
  get invalidEmail(): boolean | undefined {
    return this.email?.invalid && (this.email?.touched || this.email?.dirty);
  }

  login() {
    this.mostrarSpinner = true;
    this.recaptchaService
      .executeAsPromise(environment.RECAPTCHA_V3_SITE_KEY, 'login')
      .then(this.makeLogin.bind(this))
      .catch(err => {
        console.error(err);
        this.notificationService.showNotification(
          'Error de ReCaptcha, revise su conexión a internet o intente con otra red.'
        );
        this.mostrarSpinner = false;
      });
  }

  makeLogin(recaptcha: string) {
    this.authService
      .login(this.email?.value, this.password?.value, recaptcha)
      .pipe(take(1))
      .subscribe({
        error: (err: HttpErrorResponse) => {
          this.notificationService.showNotification(
            err.error.message,
            NOTIFICATIONS.CONSTANTS.CLOSE
          );
        },
        next: (
          authData: AuthLoginResponse<ValidateAccountResponse | LevelsAvailable>
        ) => {
          // SIN 2FA
          const without2FAResponse =
            authData as unknown as Success2FACodeValidationResponse;
          if (without2FAResponse.permissions) {
            this.authService.saveLoginData(without2FAResponse);
            if (this.isTransportistaWithoutCompany(without2FAResponse.user)) {
              this.router.navigate([
                `/${PATHS.AUTH.BASE}/${PATHS.AUTH.CUIT.BASE}`,
              ]);
              return;
            }
            this.router.navigate([
              `/${PATHS.MAIN}/${PATHS.UN.MARCAS.BASE}/${PATHS.CONTROL_DE_FLETES.BASE}`,
            ]);
          }
          // usuario no validado
          if (authData.statusCode === 300) {
            this.notificationService.showNotification(
              authData.statusText,
              NOTIFICATIONS.CONSTANTS.CLOSE
            );
          }
          if (authData.statusCode !== 201) {
            throw new Error(authData.statusText);
          }

          const { token, levels, expiration, session } =
            authData.body as LevelsAvailable;

          if (token) {
            this.localStorageService.setItem(
              LOCAL_STORAGE.CONSTANTS.TOKEN,
              token
            );

            if (expiration) {
              const expirationId = setTimeout(() => {
                this.notificationService.showNotification(
                  'A expirado el tiempo para iniciar sesión'
                );
                this.router.navigate([
                  `${PATHS.AUTH.BASE}/${PATHS.AUTH.LOGIN.BASE}`,
                ]);
              }, expiration * 1000);

              this.localStorageService.setItem(
                LOCAL_STORAGE.CONSTANTS.EXPIRATION,
                expirationId
              );
            }

            if (session) {
              this.notificationService.showNotification(session);
            }
          }

          this.localStorageService.setItem(
            LOCAL_STORAGE.CONSTANTS.LEVELS,
            levels
          );
          this.localStorageService.setItem(
            LOCAL_STORAGE.CONSTANTS.EMAIL,
            this.email?.value
          );

          if (['GOOGLE', 'META', 'MICROSOFT'].includes(levels[0].type)) {
            this.router.navigate(['/auth/social']);
            return;
          }
          if (levels[0].type === ValidationMethod.DUO) {
            this.router.navigate(['/auth/internos']);
            return;
          }
          this.router.navigate(['/auth/externos']);
        },
      })
      .add(() => (this.mostrarSpinner = false));
  }

  isTransportistaWithoutCompany(user: AuthLoginResponseStoredUser): boolean {
    const roleWithCompanies =
      user.role.tag === ROLES.USUARIO_TRANSPORTE ||
      user.role.tag === ROLES.USUARIO_CHOFER;
    return !user.userCompanies.length && roleWithCompanies;
  }
}
