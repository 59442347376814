export const TITLES = {
  CONFIGURACION: {
    ROLE_PERMISSION: {
      LIST: 'Lista de Roles y Permisos',
    },
  },
  DEPOSITOS_DE_ORIGEN: {
    LIST: 'Depósitos de Origen',
  },
  EMPRESAS: {
    LIST: 'Empresas',
  },
  FACTURAS: {
    LIST: 'Facturas',
  },
  OFERTA_DE_CAMIONES: {
    LIST: 'Oferta de camiones',
  },
  ORDENES_DE_CARGA: {
    LIST: 'Ordenes de carga',
  },
  PERFIL: {
    DETAIL: 'Perfil',
  },
  REMITOS: {
    LIST: 'Remitos',
  },
  UN: {
    MARCAS: {
      CARGA_DE_FACTURAS_Y_REMITOS: {
        LIST: 'Carga de facturas y Remitos - Marcas',
      },
      CONTROL_DE_FLETES: {
        LIST: 'Control de Fletes - Marcas',
      },
    },
    UNAGRO: {
      CARGA_DE_FACTURAS_Y_REMITOS: {
        LIST: 'Carga de facturas y Remitos - Unagro',
      },
      CONTROL_DE_FLETES: {
        LIST: 'Control de Fletes - Unagro',
      },
    },
  },
  USER: {
    LIST: 'Usuarios',
    SOLICITUDES: 'Administración de usuarios',
  },
};
