import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEsAR from '@angular/common/locales/es-AR';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environment/environment';
import { RemitosModule } from '@modules/layout/remitos/remitos.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { DeviceHelper } from './core/utils/device.helper';
import { AuthModule } from './modules/auth/auth.module';
import { MarcasModule } from './modules/layout/un/marcas/marcas.module';

const config: SocketIoConfig = {
  options: { autoConnect: false, query: { token: '' } },
  url: environment.SOCKET_URL,
};

registerLocaleData(localeEsAR);
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    InfiniteScrollModule,
    NgxSpinnerModule.forRoot({ type: 'ball-beat' }),
    MarcasModule,
    RemitosModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    DeviceHelper,
  ],
})
export class AppModule {}
