<div class="outer-container">
  <div class="header-container">
    <app-header-login></app-header-login>
  </div>

  <div class="forms-container mt-20">
    <h1 class="h1">Verifique su identidad</h1>
    <div class="text-center mt-5 mb-15">
      Necesitamos que ingreses a través de {{ this.provider[0].type }} ya que
      fue su método de registro.
    </div>
    <app-oauth-login [provider]="this.provider[0].type"></app-oauth-login>
    <button
      mat-fab
      extended
      class="button-transparent"
      routerLink="/auth/login">
      Seleccionar otro método de autenticación
    </button>
  </div>

  <app-copyright></app-copyright>
</div>
