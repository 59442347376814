<div class="outer-container">
  <div class="header-container">
    <app-header-login></app-header-login>
  </div>

  <div class="forms-container mt-20">
    <mat-icon class="icon-72 red mb-30">phonelink_erase</mat-icon>
    <h1 class="h1 w-328 text-center">No pudimos constatar su identidad</h1>
    <div class="text-center mb-30">
      Asegúrese de tener su número de teléfono asociado correctamente a la
      cuenta.
    </div>

    <div class="simple-container">
      <button mat-fab extended class="button-transparent" (click)="goToLogin()">
        Volver a intentar
      </button>
    </div>
  </div>

  <app-copyright></app-copyright>
</div>
